body {
    font-size: 1.5em;
}

.brand {
    text-decoration: none;
    color: #fff;
    font-size: 1.5em;
    
}

.brand:hover {
    text-decoration: none;
    color: #fff;
}

.boxx {
    border: 2px solid #fff;
    border-radius: 25px;
    padding-right: 20px;
}

.boxxx {
    padding-right: 20px; 
}

.logout-box:hover {
    cursor: pointer;

}